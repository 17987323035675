  <template>
    <v-app style="max-width:425px; margin-left:auto; margin-right:auto">
      <div class="loginBackground visbyRoundFont">
        
        <div class="doctorBackground">
          <div style="padding: 16px;">
          <language-change-part />
          <img src="https://s3iconimages.mymedicine.com.mm/3dDoctors.svg" alt="" style="height: 242px; object-fit: contain;">
        </div>
        </div>
        <div style="padding: 24px;">
        <div style="display: flex; flex-direction: column; align-items: flex-start;">
          <div style="display: flex; align-items: center; gap: 4px;">
            <span style="color: #4aaaf2; font-size: 36px; font-weight: 550; line-height: 36px;;">We Care</span>
            <img src="https://s3iconimages.mymedicine.com.mm/heart.gif" alt="" style="height: 42px;">
          </div>
          <div>
            <span style=" font-size: 36px; font-weight: 550; line-height: 36px;">Your Health</span>
          </div>
        </div>
        <v-form ref="form" v-model="formValid">
        <div style="display: flex; flex-direction: column; align-items: flex-start; margin-top: 40px; gap: 16px;">
        <div style="font-weight: 600; font-size: 20px;">Mobile Number<span style="color: red;">*</span></div>
          <v-text-field
            v-model="phone_number"
            placeholder="000 000 000"
            style="width: 100%; "
            outlined
            type="number"
            :rules="$store.state.locale == 'en' ? mobileNumberRules : mobileNumberRulesMM"
          >
            <template v-slot:prepend-inner>
              <div style="display: flex; align-items: center;">
              <v-avatar size="24" class="mr-2">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Flag_of_Myanmar.svg/32px-Flag_of_Myanmar.svg.png" alt="Myanmar flag">
              </v-avatar>
              <span>(+95)</span>
              </div>
            </template>
          </v-text-field>
        </div>
        <v-btn elevation="0" block color="#4aaaf2" class="custom-button" style="text-transform: none; height: 48px;" @click="sendOTP" :loading="otpLoading" :disabled="otpLoading ||!formValid"><span style="color: #fff; font-weight: 500; " >Verify</span></v-btn>
      </v-form>
      </div>
    </div>
</v-app>
</template>

<script>

import axios from "axios";
import languageChangePart from './languageChangePart.vue';
export default {
  components: { languageChangePart },
  data() {
    return {
      otpLoading: false,
      phone_number: "",
      formValid: false,
      LanguageList: [{"item-text":"English","item-value":"en","image":"https://s3iconimages.mymedicine.com.mm/usa_flag.svg"},{"item-text":"Burmese","item-value":"mm","image": "https://s3iconimages.mymedicine.com.mm/myanmar_flag.svg"}],
      Language: "",
      mobileNumberRules: [
                        v => (v && (v[0] == '0')) || 'Mobile Number must start with 0',
                        v => !!v || 'Mobile Number is required',
                        v => (v && v.length <= 11 && v.length >=7) || 'Mobile Number should be between 7 to 11 numbers',
                        v => v > 0 || 'Mobile number cannot be negative',
                        ],
      mobileNumberRulesMM: [
                        v => (v && (v[0] == '0')) || 'Mobile Number must start with 0',
                        v => !!v || 'ဖုန်းနံပါတ်ထည့်သွင်းရန် လိုအပ်ပါသည်။',
                        v => (v && v.length <= 11 && v.length >=7) || 'ဖုန်းနံပါတ်၏ ဂဏန်းအရေအတွက်မှာ ၇ လုံးမှ ၁၁ လုံးဖြစ်ရပါမည်။',
                        v => v > 0 || 'Mobile number cannot be negative',
                        ],
    };
  },

  methods: {
    changeLanguage(){
      this.$i18n.locale = this.Language;
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    },
    async sendOTP() {
      try {
        this.otpLoading = true;
        await axios.post(process.env.VUE_APP_BACKEND_URL+"/sendOTP",{
          phoneNumber: this.phone_number,
        });
        this.otpLoading = false;
        this.$router.push({
            name: "VerifyOTP",
            params: {
              phoneNumber: this.phone_number, 
            }
            
          });
        } catch(error) {
        console.log(error);
      }

    },
  },
  mounted() {
    this.currentCustomer = this.$cookies.get("customerToken");
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.Language = this.$i18n.locale;
    if(this.currentCustomer){
        var verifyCustomerTokenData = {
            "token" : this.currentCustomer,
            "typeOfUser" : "CUSTOMER"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",verifyCustomerTokenData)
        .then((verifyCustomerTokenDataResponse) => {
          let source= 'HOME';
          console.log(source)
          this.$router.push({
              name: 'ePharmacyOdooHome',
              params: { source: source }
          });
        })
        .catch((verifyCustomerTokenDataError)=>{
          this.$cookies.remove("customerToken");
          this.$cookies.remove("access");
        });
    }
    else{
      this.$cookies.remove("customerToken");
      this.$cookies.remove("access");
    }
  },

};
</script>

<style scoped>
.v-avatar {
  display: inline-flex;
  align-items: center;
}
.loginBackground {
  /* background: url('https://s3iconimages.mymedicine.com.mm/Rectangle 3.png'); */
  min-height: 100vh;
  background-size: cover;
  padding-bottom: 20px;
}
.doctorBackground {
  background: linear-gradient(to bottom right, #d9f2f5, #fcd2db);
  /* background: url('https://s3iconimages.mymedicine.com.mm/3dDoctors.svg'); */
  height: 300px;
  /* background-size: cover; */
  border-radius: 0 0 40px 40px;
}
.font_size_12_400 {
  font-size: 14px;
  font-weight: 400;
}
.mx-input {
  height: 40px !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.custom-button {
  text-transform: none;
  border-radius: 8px;
  height: 200px;
  font-size: 18px; letter-spacing: 0;
  /* margin-top: 32px; */

}
.v-input >>> .v-text-field__details {
  /* display: none; */
}
.v-input {
  font-size: 16px;
  font-weight: 500;
}
.v-input >>> .v-input__slot {
  /* : none; */
}
.v-input >>> fieldset {
  border: 1px solid #efefef;
  border-radius: 8px;
}
/* @media (min-width: 800px){
  .loginClass{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }
  .MobileNumber{
    margin-top:14px !important
  }
  } */
</style>
